
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    InfoCard,
    InfoCardRow,
  },
  props: {
    pageTitle: {
      type: String,
    },
    chatLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { openFacebookQrCodeModal } = useChatsSettings()
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(props.chatLink)
      setTimeout(() => {
        copiedStatus.value = false
      }, 3000)
    }

    return {
      openFacebookQrCodeModal,
      copiedStatus,
      copyText,
    }
  },
})
