
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import ChatFacebookMessengerGeneral from '@/components/pages/chats/settings/facebookMessenger/detailed/ChatFacebookMessengerGeneral.vue'
import ChatFacebookMessengerSharing from '@/components/pages/chats/settings/facebookMessenger/detailed/ChatFacebookMessengerSharing.vue'
import ChatFacebookMessengerUnlink from '@/components/pages/chats/settings/facebookMessenger/detailed/ChatFacebookMessengerUnlink.vue'
import { facebookMock } from '@/definitions/chats/settings/facebookMessenger/data'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    ChatsSettingsPage,
    TmAvatar,
    DetailsHero,
    InfoCards,
    ChatFacebookMessengerGeneral,
    ChatFacebookMessengerSharing,
    ChatFacebookMessengerUnlink,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Messenger settings', name: 'base.chats.settings' },
      { label: 'Facebook Messenger', name: 'base.chats.settings.facebookMessenger' },
      { label: 'Acme LLC (Australia)' },
    ]

    const facebookPage = facebookMock[0]

    return {
      breadcrumbs,
      facebookPage,
      formatDate,
    }
  },
})
