
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    InfoCardRow,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },
  setup() {
    const { openFacebookUnlinkModal } = useChatsSettings()
    return {
      openFacebookUnlinkModal,
    }
  },
})
